<template>
  <a
    v-if="advertisement?.image"
    :href="advertisement.url"
    target="_blank"
    rel="noopener nofollow"
    class="block"
  >
    <img
      :src="imageUrl"
      :alt="advertisement.title"
    >
  </a>
</template>

<script lang="ts" setup>
import {useAdvertisementQuery} from '~/graphql/graphql';

const props = defineProps<{
  position?: string,
}>();

const {url: sofieUrl} = useSofieConfig();

const {result: advertisementQueryResult} = useAdvertisementQuery({
  position: props.position,
});
const advertisement = computed(() => advertisementQueryResult.value?.advertisement);

const imageUrl = computed(() => {
  const url = '' + advertisement.value?.image?.url;

  if (url.startsWith('/')) {
    return sofieUrl + url;
  }

  return url;
});
</script>
